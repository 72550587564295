<div class="form-error">
  @if (this.latestError) {
    {{
      validationService.getValidatorErrorMessage(
        this.latestError,
        this.errorObject[this.latestError],
        this.label
      )
    }}
  }
</div>
