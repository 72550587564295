import {HttpClient, HttpResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BehaviorSubject, catchError, EMPTY, map, Observable, throwError} from 'rxjs';
import {ApiRouteUtil} from "../../shared/utils/api-route.util";
import {ROUTE_CONSTANT} from "../../constant/constant";
import {Router} from "@angular/router";

export interface IOrganization {
  orgId: string
  admin: boolean
  clientId: string
  defaultAppClientId: string
  name: string
  userPoolId: string
}

@Injectable({
  providedIn: 'root',
})
export class DomainOrganizationService {
  organizations: BehaviorSubject<IOrganization[] | null> = new BehaviorSubject<
    IOrganization[] | null
  >(null);
  userName: string | undefined;

  constructor(
    private httpClient: HttpClient,
    private router: Router,
    ) {}

  getOrganizationsUsingLoginName(login: string): Observable<IOrganization[]> {
    return this.httpClient.get<IOrganization[]>(
      ApiRouteUtil.orgSearchByLogin(login)
    );
  }

  getOrgDetails(organizationId: string): Observable<IOrganization> {
    return this.httpClient.get<IOrganization>(
      ApiRouteUtil.orgDetails(organizationId),
      {
        observe: 'response',
        responseType: 'json',
      }
    ).pipe(
      map(response => {
        let body = response?.body;

        if ((response.status !== 200 && response.status !== 404) || !body) {
          throw Error('Unable to determine organization details.', {
            cause: response.statusText || 'Invalid response.'
          })
        }
        if (response.status === 404 || !body.orgId || !body.name || !body.userPoolId ) {
          throw new Error('404: Valid Organization not found.', { cause: 'Invalid response.'});
        }

        return body
      }),
    catchError(error => {
      console.error(error.message, error.cause);
      void this.router.navigate([ROUTE_CONSTANT.ORGANIZATION_NOT_FOUND]);
      return EMPTY
    })
    )
  }
}
