import {Injectable, signal, WritableSignal} from '@angular/core';
import {
  confirmResetPassword,
  confirmSignIn,
  ConfirmSignInOutput,
  fetchAuthSession,
  getCurrentUser,
  GetCurrentUserOutput,
  resetPassword,
  ResetPasswordOutput,
  signIn,
  SignInOutput,
} from 'aws-amplify/auth';
import {KeyValueStorageInterface} from 'aws-amplify/utils';
import {Amplify} from 'aws-amplify';
import {cognitoUserPoolsTokenProvider} from 'aws-amplify/auth/cognito';

import {JsonParserService} from './json-parser.service';
import {CognitoOrgIdService} from './cognito-org-id.service';
import {IOrganization} from "../services/domain-organization.service";

export interface IUser {
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class CognitoService {
  private orgDetails: WritableSignal<IOrganization> = signal({
    orgId: '',
    admin: false,
    clientId: '',
    defaultAppClientId: '',
    name: '',
    userPoolId: '',
  });

  constructor(private _cognitoOrgIdService: CognitoOrgIdService) {}

  public signIn(user: IUser): Promise<SignInOutput> {
    this.configureAuth(this.getOrgDetails());

    return signIn({
      username: user.email,
      password: user.password,
      options: {authFlowType: 'USER_SRP_AUTH'},
    });
  }

  public async isAuthenticated(): Promise<boolean> {
    try {
      const user = await this.getUser();
      return !!user;
    } catch {
      return false;
    }
  }

  public handleUpdatePassword(newPassword: string): Promise<ConfirmSignInOutput> {
    return confirmSignIn({challengeResponse: newPassword});
  }

  public resetPassword(username: string): Promise<ResetPasswordOutput> {
    return resetPassword({username});
  }

  public confirmResetPassword(
    username: string,
    newPassword: string,
    confirmationCode: string,
  ): Promise<void> {
    return confirmResetPassword({
      username,
      newPassword,
      confirmationCode,
    });
  }

  public async getUser(): Promise<GetCurrentUserOutput> {
    return await getCurrentUser();
  }

  public configureAuth(orgDetails: IOrganization, appClientId: string | undefined = undefined): void {
    Amplify.configure({
      Auth: {
        Cognito: {
          userPoolId: orgDetails?.userPoolId,
          userPoolClientId: appClientId || orgDetails?.defaultAppClientId,
          loginWith: {
            username: true,
            email: true,
          },
          mfa: {
            status: 'on',
            totpEnabled: true,
          },
        },
      },
    });
    cognitoUserPoolsTokenProvider.setKeyValueStorage(
      new CognitoCustomStorage(this._cognitoOrgIdService.orgId()?.toString() as string),
    );
  }

  public async getAccessToken(): Promise<string> {
    return (await fetchAuthSession()).tokens?.accessToken.toString() as string;
  }

  public getOrgDetails(): IOrganization {
    return this.orgDetails();
  }

  public setOrgDetails(orgDetails: IOrganization, appClientId: string | undefined = undefined): void {
    this.orgDetails.set(orgDetails);
    this.configureAuth(orgDetails, appClientId);
  }
}

export class CognitoCustomStorage implements KeyValueStorageInterface {
  constructor(private orgId: string) {
    if (!window.localStorage.getItem(orgId)) {
      window.localStorage.setItem(orgId, `{}`);
    }
  }
  async setItem(key: string, value: string): Promise<void> {
    const authObj = this.getAuthObj();
    authObj[key] = value;
    window.localStorage.setItem(this.orgId, JSON.stringify(authObj));
  }
  async getItem(key: string): Promise<string | null> {
    return this.getAuthObj()[key];
  }
  async removeItem(key: string): Promise<void> {
    const authObj = this.getAuthObj();
    delete authObj[key];
    window.localStorage.setItem(this.orgId, JSON.stringify(authObj));
  }
  async clear(): Promise<void> {
    window.localStorage.removeItem(this.orgId);
  }
  getAuthObj(): Record<string, string> {
    return JsonParserService.tryParseJSONObject(localStorage.getItem(this.orgId) as string) || {};
  }
}
