import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class JsonParserService {
  static tryParseJSONObject(jsonString: string) {
    try {
      const o = JSON.parse(jsonString);
      return o && typeof o === 'object' ? o : false;
    } catch {
      return false;
    }
  }
}
